import { authenticationClientProvider } from '../config/auth-provider';
import axios, { AxiosInstance } from "axios";

export enum TargetedMicroService {
  PROJECT = 'nrjezer-back'
}

export const getTokenScopes = (): string[] => {
  const scopes = [];
      if (process.env.REACT_APP_AZURE_SCOPES) {
        scopes.unshift(process.env.REACT_APP_AZURE_SCOPES);
      }
  return scopes;
};

export async function createAxiosInstance(): Promise<AxiosInstance> {
    const configAxios: AxiosInstance = axios.create();
    // console.log(configAxios);
     const accounts = authenticationClientProvider.getAllAccounts();
//  console.log('accounts are ', accounts);
  const tokenRequest = {
    account: accounts[0],
    scopes: getTokenScopes(),
  };
  try {
        if (accounts?.length > 0) {
            // console.log('tokenRequest ', tokenRequest)
            const token = await authenticationClientProvider.acquireTokenSilent(tokenRequest);
            // console.log('token: ', token);
          // configAxios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
          configAxios.defaults.headers.common = {
            "x-apif-apikey": process.env.REACT_APP_APIF_KEY,
            "Authorization":`Bearer ${token.accessToken}`,
          };
          // configAxios.defaults.headers.subscription-key = process.env.x-apif-apikey;
        } 
     } catch (error) {
        console.error(error);
      }

return configAxios;
}